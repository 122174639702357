@import url(https://fonts.googleapis.com/css2?family=Open+Sans);
body {
  margin-top: 0px !important;
}

body.branch-banner-is-active {
  margin-top: 76px !important;
}

.gallery-image-wrapper {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.gallery-image-wrapper img {
  width: auto;
  max-height: 100vh;
  max-width: 100%;
}
body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  background-color: white;
}

.large ul {
  list-style-type: none !important;
}
.large ul li {
  position: relative;
}
.large ul li:before {
  content: "";
  height: 6px;
  width: 6px;
  background: black;
  position: absolute;
  top: 7px;
  left: -20px;
  border-radius: 2px;
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}

.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}
