body {
  margin-top: 0px !important;
}

body.branch-banner-is-active {
  margin-top: 76px !important;
}

.gallery-image-wrapper {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.gallery-image-wrapper img {
  width: auto;
  max-height: 100vh;
  max-width: 100%;
}